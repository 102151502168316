import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { DateTime } from "luxon";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
    },
  },
};

export function TransactionDataSummaryChart({ data, selected }) {
  const labels =
    selected === "month"
      ? [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ].filter((each, i) => i <= new Date().getMonth())
      : [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ].filter((each, i) => i <= new Date().getDay());
  const [cdata, setcData] = useState(labels.map(() => 0));

  useEffect(() => {
    if (data) {
      let totals = labels.map((e, i) => ({ t: i, total: 0 }));
      for (let i = 0; i < data.length; i++) {
        const t = new Date(data[i].createdAt);
        const weekNum = DateTime.utc(
          t.getFullYear(),
          t.getMonth() + 1,
          t.getDate()
        ).weekNumber;
        if (
          selected === "month" &&
          t.getFullYear() === new Date().getFullYear()
        ) {
          totals = totals.map((e) =>
            e.t === t.getMonth() ? { ...e, total: e.total + 1 } : e
          );
        } else if (DateTime.now().weekNumber === weekNum) {
          totals = totals.map((e) =>
            e.t === t.getDay() ? { ...e, total: e.total + 1 } : e
          );
        }
      }
      setcData(totals.map((e) => e.total));
    }
    //eslint-disable-next-line
  }, [selected, data]);

  return (
    <Line
      options={options}
      data={{
        labels,
        datasets: [
          {
            label: "",
            data: cdata,
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            fillStyle: "rgba(100,100,100,0.8)",
          },
        ],
      }}
    />
  );
}
