import React from "react";
import { ReactSVG } from "react-svg";
import styled, { css } from "styled-components";
import icons from "./icons";
import PropTypes from "prop-types";

const StyledSVGIcon = styled(ReactSVG)`
  svg {
    fill: black;
    ${({ size }) =>
      size &&
      css`
        width: ${size}px;
        height: ${size}px;
        min-width: ${size}px;
        min-height: ${size}px;
      `}
    ${({ transform }) =>
      transform &&
      css`
        transform: ${transform};
      `}
    path {
      ${({ color }) =>
        color &&
        css`
          fill: ${color};
        `}
    }
  }
`;

const CustomIcon = ({ name, color, size, transform, isClickable, onClick }) => {
  if (!name) return null;

  function handleClick() {
    if (!isClickable) return;
    onClick();
  }

  return (
    <div
      onClick={handleClick}
      className={`${isClickable ? "cursorPointer" : ""}`}
    >
      <StyledSVGIcon
        src={icons[name]}
        color={color}
        size={size || 15}
        transform={transform}
      />
    </div>
  );
};

CustomIcon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
  isClickable: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CustomIcon;
