import dayjs from "dayjs";
import { formatNumberInThousand } from "../../utils/functions";
import { useState } from "react";
import RequestDetailsModal from "./group-details/RequestDetailsModal";
import { nairaSign } from "../../utils/constants";

const tableHeader = [
  "Groups's Name",
  "Ajo Amount",
  "Balance",
  "StartDate",
  "EndDate",
  "Status",
  "Action",
];

export default function GroupRequestTable({ transactions }) {
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [selectedTnx, setSelectedTnx] = useState();

  function handleViewTransaction(tnx) {
    setSelectedTnx(tnx);
    setOpenTransactionModal(true);
  }

  return (
    <div className="bgWhite cardShadow p-3">
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="fw-bold">Recent Requests</div>
        {/* <button className="btn btn-sm textSecondary">View All</button> */}
      </div>
      <div className="table-responsive-lg">
        <table className="table fw-bold small">
          <thead className="tableHeader">
            <tr>
              {tableHeader.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {transactions.map((item, index) => (
              <tr key={index}>
                <td>{item?.group_name}</td>
                <td>{nairaSign + formatNumberInThousand(item?.ajo_amount)}</td>
                <td>{nairaSign + formatNumberInThousand(item?.balance)}</td>
                <td>{dayjs(item?.startDate).format("MMM DD, YYYY")}</td>
                <td>{dayjs(item?.endDate).format("MMM DD, YYYY")}</td>
                {/* <td>
                  {item.amount < 0 ? (
                    <span className="text-danger">
                      {nairaSign + formatNumberInThousand(item?.amount)}
                    </span>
                  ) : (
                    <span className="text-success">
                      {nairaSign + formatNumberInThousand(item?.amount)}
                    </span>
                  )}
                </td> */}
                <td>
                  {item.status === "approved" ? (
                    <span className="text-success">{item.status}</span>
                  ) : item.status === "pending" ? (
                    <span className="text-warning">{item.status}</span>
                  ) : (
                    <span className="text-danger">{item.status}</span>
                  )}
                </td>
                {/* <td>{item?.request_type}</td>q */}
                <td>
                  <button
                    className="btn btn-sm fw-bold"
                    onClick={() => handleViewTransaction(item)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <RequestDetailsModal
        open={openTransactionModal}
        onClose={() => setOpenTransactionModal(false)}
        transaction={selectedTnx}
      />
    </div>
  );
}
