/* eslint-disable linebreak-style */
/* eslint-disable no-console */
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { nairaSign } from "../../../utils/constants";
import CustomIcon from "../../../widgets/icon/CustomIcon";
import { formatNumberInThousand } from "../../../utils/functions";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { loginSelector } from "../../../store/slices/auth/loginSlice";
import CustomModal from "../../../widgets/modals/CustomModal";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import baseUrl from "../../../utils/baseUrl";
import { useLocation, useNavigate } from "react-router-dom";

export default function RequestDetails({ data, onClose }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [declineReason, setDeclineReason] = useState("");
  const [groupAdmin, setGroupAdmin] = useState("");
  const [messageResponse, setMessageResponse] = useState("");
  const { userData } = useSelector(loginSelector).login;

  // Reloading page
  const fetchData = useCallback(async () => {
    setLoading(true);
    // Simulating API call
    await new Promise(resolve => setTimeout(resolve, 1000));
    setLoading(false);
    // Here you would typically set some state with the fetched data
    console.log('Data reloaded');
  }, []);

  const handleOnLoad = async () => {
    const response = await axios.get(
      `${baseUrl}admin/get_group_admin/${data?.admin_id}`,
    );
    const datas = response.data;
    console.log(datas.data)
    setGroupAdmin(`${datas.data.first_name} ${datas.data.last_name}`)
  }

  useEffect(()=>{
    handleOnLoad();
  },[]);

  // Decline
  const handleDecline = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(true);
    }, 1500);
  };

  const handleApprove = async () => {
    setLoading(true);
    setOpen(false);
    let dataRequest = {
      group_id: data?.id,
      adminId: userData.id,
    };
    try {
      const response = await axios.post(
        `${baseUrl}admin/approve-group-request`,
        dataRequest
      );
      const data = response.data;
      setMessageResponse(data.message);
      if (!data.error || data.status) {
        setLoading(false);
        setTimeout(() => {
          setDeclineReason("");
          onClose();
        }, 2000);

        window.location.reload();
      }
    } catch (err) {
      console.error("err ", err);
      setLoading(false);
      setMessageResponse("An error occur, please try again.");
    }
  };

  const handleDeclineSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setOpen(false);
    let dataRequest = {
      group_id: data?.id,
      adminId: userData.id,
      reason: declineReason,
    };
    try {
      const response = await axios.post(
        `${baseUrl}admin/decline-group-request`,
        dataRequest
      );
      const data = response.data;
      setMessageResponse(data.message);
      if (!data.error || data.status) {
        setLoading(false);
        setTimeout(() => {
          setDeclineReason("");
          onClose();
        }, 2000);
        window.location.reload();
      }
    } catch (err) {
      console.error("err ", err);
      setLoading(false);
      setMessageResponse("An error occur, please try again.");
    }
  };
  return (
    <>
      <center>
        <small className="text-warning">{messageResponse}</small>
      </center>
      <div className="fw-light mt-4 mb-3 small">
        {dayjs(data?.createdAt).format("MMM DD, YYYY HH:ss")}
      </div>
      <ReceiptLine
        verticalReverse={true}
        leadTitle={true}
        title={data?.description}
        value={data?.group_name + " - " +nairaSign + formatNumberInThousand(data?.ajo_amount)}
      />
      <ReceiptLine title="Admin" value={`${groupAdmin ?? 'N/A'}`} />
      <ReceiptLine title="Group Balance" value={nairaSign + data?.balance} />
      <ReceiptLine title="Group Fix Amount" value={nairaSign + data?.ajo_amount} />
      <ReceiptLine title="Status" value={data?.status} />
      {/* <ReceiptLine
        title="Payment Method"
        value="Bank Transfer"
        appendTitle="Fees"
        appendValue={`${nairaSign}0.00`}
      /> */}
      <ReceiptLine
        title="Group reference"
        value={data?.id}
      />
      <ReceiptLine title="Start Date" value={data?.startDate} />
      <ReceiptLine title="End Date" value={data?.endDate} />

      {data.status === "pending" && (
        <div className="container mt-5">
          <div className="row">
            <div className="col-6 justify-contents-center align-items-center">
              <button
                type="button"
                onClick={handleDecline}
                disabled={loading}
                className="btn btn-danger btn-block"
              >
                Decline
              </button>
            </div>
            <div className="col-6 justify-contents-center align-items-center">
              <button
                type="button"
                onClick={handleApprove}
                disabled={loading}
                className="btn btn-success btn-block float-end"
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      )}

      {loading && (
        <div
          className="position-absolute top-0 bottom-0 start-0 w-100 d-flex justify-content-center align-items-center"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="spinner-border text-light" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      <CustomModal
        open={open}
        isFullscreen
        dialogClassName={"w-md-50"}
        title={`Decline ${data?.account_name}'s Request`}
        position={"left"}
        onClose={() => setOpen(false)}
      >
        <Form onSubmit={handleDeclineSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Please provide a reason for declining this request:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={declineReason}
              onChange={(e) => setDeclineReason(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="danger" type="submit">
            Submit
          </Button>
        </Form>
      </CustomModal>
    </>
  );
}


function ReceiptLine({
  title,
  verticalReverse,
  leadTitle,
  value,
  appendTitle,
  appendValue,
  appendIcon,
}) {
  return (
    <div className="d-flex justify-content-between align-items-center border-bottom py-2 mb-3">
      <div className="w-100">
        <ReceiptInfo
          verticalReverse={verticalReverse}
          leadTitle={leadTitle}
          title={title}
          value={value}
        />
      </div>
      {appendTitle ? (
        <ReceiptInfo title={appendTitle} value={appendValue} />
      ) : (
        <CustomIcon name={appendIcon} size={40} />
      )}
    </div>
  );
}

function ReceiptInfo({ title, value, leadTitle, verticalReverse }) {
  return (
    <div
      className={`d-flex ${
        verticalReverse ? "flex-column-reverse" : "flex-column"
      }`}
    >
      {title && <div className="fw-light small mb-2">{title}</div>}
      {value && (
        <div className={`fw-bold small ${leadTitle ? "lead" : ""}`}>
          {value}
        </div>
      )}
    </div>
  );
}

RequestDetails.propTypes = {
  data: PropTypes.object,
};

ReceiptLine.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  verticalReverse: PropTypes.bool,
  leadTitle: PropTypes.bool,
  appendTitle: PropTypes.string,
  appendValue: PropTypes.string,
  appendIcon: PropTypes.string,
};

ReceiptInfo.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  leadTitle: PropTypes.bool,
  verticalReverse: PropTypes.bool,
};
