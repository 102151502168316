import React, {useState} from "react";
import styles from "./page-header.module.css";
import backIcon from "../../assets/icons/back.svg";
import searchIcon from "../../assets/icons/search.svg";
import {useLocation, useNavigate} from "react-router-dom";
import Filter from "../filter/Filter";
import {getMerchants, verifyMerchant} from "../../store/slices/dashboard/dashboardSlice";
import {useDispatch, useSelector} from "react-redux";
import {loginSelector} from "../../store/slices/auth/loginSlice";
import toast from "react-hot-toast";

const PageHeader = ({
  title,
  titlePosition,
  withSearch,
  withFilter,
  withCenterButton,
  CenterButton,
  merchantDetails,
  setFilter,
  search
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  function handleGoBack() {
    navigate(-1);
  }
  const downloadDocs = () => {
    merchantDetails.kycs.forEach(element => {
      if (element.POF) {
        window.open(element.POF)
      }
    });
  }

  const [verifying, setVerifying] = useState(false);
  const {userData: {id}} = useSelector(loginSelector).login;
  const dispatch = useDispatch();
  const markMerchantAsVerified = () => {
    setVerifying(true);
    verifyMerchant({adminId: id, agentId: merchantDetails.id}).then(async res => {
      if (res.status) {
        toast.success("Merchant verified successfully")
        toast('Updating merchant details...', {duration: 5000});
        await dispatch(getMerchants(true));
      } else {
        toast.error("An error occured")
      }
      setVerifying(false);
    }).catch(err => {
      toast.error("An error occured")
      setVerifying(false);
    })
  }

  return (
    <>
      <div className="bgLightBlue py-4 mb-4">
        <div className="container-fluid px-md-5">
          {location?.pathname !== "/" && (
            <button
              onClick={handleGoBack}
              className="btn btn-secondary d-flex align-items-center mb-4"
            >
              <img src={backIcon} width="13" alt="back" /> &nbsp;
              <small>Back</small>
            </button>
          )}
          {merchantDetails && (
            <div className="row">
              {/* <div className="col-sm-12 col-md-4 mb-3">
                <h5 className="fw-bold">Loan Detail</h5>
              </div> */}
              <div className="">
                <div className="d-flex flex-wrap">
                  <div className="my-3 mx-2">
                    {/* <img className={styles.avatar} alt="avatar" /> */}
                    <div className={styles.avatar}>{merchantDetails.first_name?.charAt(0)} {merchantDetails.last_name?.charAt(0)}</div>
                  </div>
                  <div className="m-3 mx-md-5">
                    <DetailCard
                      title="Merchant's Name"
                      content={`${merchantDetails.first_name} ${merchantDetails.last_name} ${merchantDetails?.middle_name}`}
                    />
                  </div>
                  <div className="m-3 mx-md-5">
                    <DetailCard
                      title="Title"
                      content={merchantDetails?.title}
                    />
                  </div>
                  <div className="m-3 mx-md-5 text-capitalize">
                    <DetailCard
                      title="Marital status"
                      content={merchantDetails?.marital_status}
                    />
                  </div>
                  <div className="m-3 mx-md-5 text-capitalize">
                    <DetailCard title="Gender" content={merchantDetails.gender} />
                  </div>
                  <div className="m-3 mx-md-5">
                    <DetailCard title="Date Joined" content={new Date(merchantDetails.createdAt).toLocaleString()} />
                  </div>
                  <div className="m-3 mx-md-5">
                    <DetailCard title="Phone Number" content={merchantDetails.phone} />
                  </div>
                  <div className="m-3 mx-md-5">
                    <DetailCard title="Date of Birth" content={merchantDetails.dob} />
                  </div>
                  {
                    (merchantDetails.accounts && merchantDetails.addresses) &&
                    <>
                      <div className="m-3 mx-md-5">
                        <DetailCard title="Account Number" content={merchantDetails.accounts[0]?.accountNumber} />
                      </div>
                      <div className="m-3 mx-md-5">
                        <DetailCard title="Nationality" content={merchantDetails.addresses[0].nationality} />
                      </div>
                      <div className="m-3 mx-md-5">
                        <DetailCard title="State of Origin" content={merchantDetails.addresses[0].state_of_origin} />
                      </div>
                      <div className="m-3 mx-md-5">
                        <DetailCard title="State of Residence" content={merchantDetails.addresses[0].state_of_residence} />
                      </div>
                      <div className="m-3 mx-md-5">
                        <DetailCard title="Lga of Origin" content={merchantDetails.addresses[0].lga_of_origin} />
                      </div>
                      <div className="m-3 mx-md-5">
                        <DetailCard title="Lga of Residence" content={merchantDetails.addresses[0].lga_of_residence} />
                      </div>
                      <div className="m-3 mx-md-5">
                        <div>
                          <div className="extraSmallText">Address</div>
                          <div className="fw-bold small text-capitalize" style={{maxWidth: '18rem'}}>
                            {merchantDetails.addresses[0]?.address_line_1} <br />
                            {merchantDetails.addresses[0]?.address_line_2} <br />
                            {merchantDetails.addresses[0]?.address_line_3}
                          </div>
                        </div>
                      </div></>
                  }
                  {
                    merchantDetails.guarantors &&
                    merchantDetails.guarantors.map((each, i) => (
                      <div className="m-3 mx-md-5" key={i}>
                        <div>
                          <div className="extraSmallText">Guarantor {i + 1}</div>
                          <div className="fw-bold small">
                            {each.guarantorFName} {each.guarantorLName} <br />
                            {each.guarantorEmail} <br />
                            {each?.guarantorPhone}
                          </div>
                        </div>
                      </div>
                    ))
                  }
                  <div className="m-3 w-100">
                    <button onClick={downloadDocs} className="customPrimaryButton btn me-3">
                      View Merchant proof of Identity
                    </button>

                    {!merchantDetails.verified && <button disabled={verifying} onClick={markMerchantAsVerified} className="customPrimaryButton btn">
                      {verifying ? 'Verifying...' : 'Verify Merchant'}
                    </button>}
                  </div>
                </div>
              </div>
            </div>
          )}
          {!merchantDetails && (
            <div className="row">
              <div className="col-sm-12 col-md-6">
                {titlePosition === "secondary" && (
                  <h3 className="fw-bold">{title}</h3>
                )}
                {withFilter && <Filter setFilter={setFilter} />}
              </div>
              <div className="col-sm-12 col-md-2">
                {CenterButton}
              </div>
              <div className="col-sm-12 col-md-4">
                {withSearch && (
                  <div className="input-group mb-3">
                    <input
                      onChange={(e) => search(e.target.value)}
                      type="text"
                      className={[
                        "form-control border-0",
                        styles.pageHeaderSearchInput,
                      ].join(" ")}
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                    />
                    <span
                      className="input-group-text border-0"
                      id="basic-addon2"
                    >
                      <img src={searchIcon} width="15" alt="search" />
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {titlePosition !== "secondary" && (
        <div className="container-fluid px-md-4">
          <div className="row">
            <div className="col-12">
              <h3 className="fw-bold textPrimary">{title}</h3>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PageHeader;

function DetailCard({title, content}) {
  return (
    <div>
      <div className="extraSmallText">{title}</div>
      <div className="fw-bold small">{content}</div>
    </div>
  );
}
