import Logo from "../widgets/logo/Logo";
import styles from "./layout-auth.module.scss";

export default function LayoutAuth({ children }) {
  return (
    <div className={["container-fluid", styles.container].join(" ")}>
      <div className="row p-4">
        <div className="col-12">
          <Logo color="white" />
        </div>
      </div>
      <div className="row text-white">
        <div className="col-sm-12 col-md-6 col-lg-5 px-lg-5 offset-md-6 offset-lg-7 mt-5">
          {children}
        </div>
      </div>
    </div>
  );
}
