import LoginForm from "../../../components/auth/login/LoginForm";
import LayoutAuth from "../../../layouts/LayoutAuth";

function Login() {
  return (
    <LayoutAuth>
      <div className="my-5">
        <h2 className="fw-bold">Log In</h2>
        <div>Enter your credentials to log in</div>
      </div>

      <LoginForm />
    </LayoutAuth>
  );
}

export default Login;
