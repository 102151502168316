import { useState } from "react";
import CustomModal from "../../widgets/modals/CustomModal";
import CustomButton from "../../widgets/form/custom-button/CustomButton";
import { useDispatch } from "react-redux";
import { deleteMember } from "../../store/slices/dashboard/dashboardSlice";
import { useSelector } from "react-redux";
import { loginSelector } from "../../store/slices/auth/loginSlice";
const tableHeader = ["Name", "Email", "Phone Number", "Role", "Action"];

export default function TeamsTable({ tableRef, teams }) {
  const {
    userData: { id },
  } = useSelector(loginSelector).login;
  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [tobeDeleted, setTobeDeleted] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const dispatch = useDispatch();
  function handleDeleteUser(admin) {
    setTobeDeleted(admin);
    setDeleteMessage(null);
    setOpen(true);
  }

  const deleteAdmin = async () => {
    setDeleting(true);
    setDeleteMessage(null);
    await dispatch(
      deleteMember(
        {
          adminId: tobeDeleted?.id,
          super_adminId: id,
        },
        {
          setMessage: setDeleteMessage,
          setOpen,
          setTobeDeleted,
        }
      )
    );

    setDeleting(false);
  };

  return (
    <div className="bgWhite cardShadow p-3">
      <CustomModal
        onClose={() => !deleting && setOpen(false)}
        open={open}
        title={`Delete Admin Account`}
      >
        {deleteMessage && typeof deleteMessage.text === "string" && (
          <div
            className={
              deleteMessage.error
                ? "text-danger text-center"
                : "text-success text-center"
            }
          >
            {deleteMessage.text}
          </div>
        )}
        <p className="h4 text-center mt-5">
          You are about to delete {tobeDeleted?.first_name}{" "}
          {tobeDeleted?.last_name} from Admin Lists
        </p>
        <small className="text-center d-block text-secondary">
          This action cannot be undo
        </small>
        <span className="d-block text-center my-4">
          <CustomButton
            onClick={deleteAdmin}
            text="Continue"
            loading={deleting}
            color="primary"
          />
        </span>
      </CustomModal>
      <div className="table-responsive-lg">
        <table className="table fw-bold small" ref={tableRef} id="teamsTable">
          <thead className="tableHeader">
            <tr>
              {tableHeader.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {teams?.map((item, index) => (
              <tr key={index}>
                {/* <td>{item.id}</td> */}
                <td>{`${item.first_name} ${item.last_name}`}</td>
                <td>{item.email}</td>
                <td>{item.phone}</td>
                <td>{item.role}</td>
                <td>
                  <button
                    onClick={() => handleDeleteUser(item)}
                    className="btn btn-sm btn-outline-secondary fw-bold"
                  >
                    Delete User
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
