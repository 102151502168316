
import {useFormik} from "formik";
import * as Yup from "yup";
import CustomLabel from "../../widgets/form/custom-label/CustomLabel";
import CustomInput from "../../widgets/form/custom-input/CustomInput";
import {useDispatch} from "react-redux";
import {useState} from "react";
import useVerify from "../../hooks/useVerify";
import CustomButton from "../../widgets/form/custom-button/CustomButton";
import {newMember} from "../../store/slices/dashboard/dashboardSlice";
import CustomSelect from "../../widgets/form/custom-select/CustomSelect";
import {useSelector} from "react-redux";
import {loginSelector} from "../../store/slices/auth/loginSlice";

const validationSchema = Yup.object({
    first_name: Yup.string("Enter user first name")
        .min(2, "Too short!")
        .max(50, "Too long!")
        .required("First Name is required"),
    last_name: Yup.string("Enter user last name (surname)")
        .min(2, "Too short!")
        .max(50, "Too long!")
        .required("Last Name (surname) is required"),
    middle_name: Yup.string("Enter user Middle name")
        .min(2, "Too short!")
        .max(50, "Too long!"),
    phone: Yup.string("Enter Phone Number")
        .max(11, "Too long!")
        .required("Phone Number is required"),
    password: Yup.string("Enter Password")
        .min(8, "Too Short!")
        .required("Password is required"),
    email: Yup.string("Enter user email")
        .email("Not a valid email")
        .required("User email is required"),
    role: Yup.string("Enter user role")
        .required("User role is required")
});

export default function NewMember({changeStep}) {
    const {userData: {id}} = useSelector(loginSelector).login
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);

    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            middle_name: "",
            phone: "",
            email: "",
            password: "",
            role: "admin"
        },
        validationSchema,
        onSubmit: (values, {resetForm}) => handleSubmit(values, resetForm),
    });

    const dispatch = useDispatch();
    const verify = useVerify();

    async function handleSubmit(values, resetForm) {
        setLoading(true);
        await dispatch(newMember({...values, super_adminId: id}, {
            setMessage,
            resetForm
        }));
        setLoading(false);
        // to refetch user data
        verify()
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            {(message && typeof message.text === 'string') &&
                <div className={message.error ? 'text-danger' : 'text-success'}>{message.text}</div>
            }
            <div className="mb-3">
                <CustomLabel htmlFor="first_name" content="First Name" />
                <CustomInput
                    type="text"
                    placeholder="Enter First Name"
                    aria-label="first_name"
                    aria-describedby="first_name"
                    name="first_name"
                    onChange={formik.handleChange}
                    value={formik.values.first_name}
                />
                {formik.touched.first_name && <div className="form-text text-danger">{formik.errors.first_name}</div>}
            </div>
            <div className="mb-3">
                <CustomLabel htmlFor="last_name" content="Last Name" />
                <CustomInput
                    type="text"
                    placeholder="Enter Last Name"
                    aria-label="last_name"
                    aria-describedby="last_name"
                    name="last_name"
                    onChange={formik.handleChange}
                    value={formik.values.last_name}
                />
                {formik.touched.last_name && <div className="form-text text-danger">{formik.errors.last_name}</div>}
            </div>
            <div className="mb-3">
                <CustomLabel htmlFor="middle_name" content="Middle Name" />
                <CustomInput
                    type="text"
                    placeholder="Enter Middle Name"
                    aria-label="middle_name"
                    aria-describedby="middle_name"
                    name="middle_name"
                    onChange={formik.handleChange}
                    value={formik.values.middle_name}
                />
                {formik.touched.middle_name && <div className="form-text text-danger">{formik.errors.middle_name}</div>}
            </div>
            <div className="mb-3">
                <CustomLabel htmlFor="phone" content="Phone Number" />
                <CustomInput
                    type="phone"
                    aria-label="Phone"
                    aria-describedby="phone"
                    withPrepend
                    name="phone"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                />
                {formik.touched.phone && <div className="form-text text-danger">{formik.errors.phone}</div>}
            </div>
            <div className="mb-3">
                <CustomLabel htmlFor="email" content="Email" />
                <CustomInput
                    type="email"
                    aria-label="Email"
                    aria-describedby="email"
                    withPrepend
                    prependValue="Email"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                />
                {formik.touched.email && <div className="form-text text-danger">{formik.errors.email}</div>}
            </div>
            <div className="mb-3">
                <CustomLabel htmlFor="role" content="Role" />
                <CustomSelect
                    options={[
                        <option value="admin">Admin</option>,
                        <option value="super-admin">Main Admin</option>
                    ]}
                    type="text"
                    placeholder="Role"
                    aria-label="Role"
                    aria-describedby="role"
                    name="role"
                    onChange={formik.handleChange}
                // value={formik.values.role}
                />
                {formik.touched.role && <div className="form-text text-danger">{formik.errors.role}</div>}
            </div>
            <div className="mb-3">
                <CustomLabel htmlFor="password" content="Password" />
                <CustomInput
                    type="password"
                    placeholder="Password"
                    aria-label="Password"
                    aria-describedby="password"
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                />
                {formik.touched.password && <div className="form-text text-danger">{formik.errors.password}</div>}
            </div>
            <div className="d-flex justify-content-between">
                {/* <button className="btn customButton customGreyButton" onClick={handleGoBack} type="button">Back</button> */}
                <CustomButton
                    type="submit"
                    loading={loading}
                    text="Add new Member"
                    color="primary"
                />
            </div>
        </form>
    )
}