import Navbar from "../widgets/navbar/Navbar";

export default function LayoutProtected({ children }) {
  return (
    <>
      <Navbar />
      {children}
    </>
  );
}
