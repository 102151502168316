import dayjs from "dayjs";
import {Link} from "react-router-dom";
import './index.css'

const tableHeader = [
  "Name",
  "Phone Number",
  "Account Number",
  "Email",
  "Joined",
  "Action",
];

export default function MerchantsTable({tableRef, merchants}) {
  return (
    <div className="bgWhite cardShadow p-3">
      <div className="table-responsive-lg">
        <table ref={tableRef} className="table fw-bold small" id="merchantsTable">
          <thead className="tableHeader">
            <tr>
              {tableHeader.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {merchants.map((item, index) => (
              <tr key={index}>
                <td>
                  {item.verified && <div className="bg-success d-inline-block tbadge"></div>}
                  {item.first_name} {item.last_name}
                </td>
                <td>{item.phone}</td>
                <td>{item.accounts[0]?.accountNumber}</td>
                <td>{item.email}</td>
                <td>{dayjs(item.createdAt).format("MMM DD, YYYY")}</td>
                <td>
                  <Link to={`/merchant/${item.phone}`} className="btn btn-sm fw-bold">
                    View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
