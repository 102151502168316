import { useState } from "react";
import NewMember from "../../components/teams/NewMember";


import PageHeader from "../../widgets/page-header/PageHeader";

export default function AddNewMember() {
    const [step, setStep] = useState(1);

    function handleChangeStep(val) {
        setStep(val);
    }

    return (
        <>
            <PageHeader title="Add New Member" />
            <div className="container-fluid px-3">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            {/* <div className="fw-bold">
                                {step === 1 && "Add New Member"}
                                {step === 2 && "Bank Details"}
                                {step === 3 && "Contribution Info"}
                            </div> */}
                            {/* <div className="fw-bold">
                                <span className="h3 fw-bold">{step}</span>/3
                            </div> */}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="customFormCard">
                            {step === 1 && <NewMember changeStep={handleChangeStep} />}
                            {/* {step === 2 && <AddBankDetails changeStep={handleChangeStep} />}
                            {step === 3 && <AddContributionInfo changeStep={handleChangeStep} />} */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}