import PropTypes from "prop-types";
import TransactionDetails from "./RequestDetails";
import CustomModal from "../../../widgets/modals/CustomModal";

const RequestDetailsModal = ({
  open,
  onClose,
  title,
  position,
  dialogClassName,
  transaction,
}) => {
  return (
    <CustomModal
      open={open}
      isFullscreen
      dialogClassName={dialogClassName}
      title={title}
      position={position}
      onClose={onClose}
    >
      <TransactionDetails data={transaction} onClose={onClose} />
    </CustomModal>
  );
};

RequestDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  position: PropTypes.string,
  dialogClassName: PropTypes.string,
  transaction: PropTypes.object,
};

RequestDetailsModal.defaultProps = {
  title: "Funding Request Details",
  position: "right",
  dialogClassName: "w-md-50",
};

export default RequestDetailsModal;
