import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { dashboardSelector } from "../../store/slices/dashboard/dashboardSlice";
import PageHeader from "../../widgets/page-header/PageHeader";

export default function MerchantDetails() {
  const [merchant, setMerchant] = useState({});
  const { merchants } = useSelector(dashboardSelector).dashboard;
  const { phone } = useParams();
  const navigate = useNavigate();
  const getMerchant = useCallback(
    () => {
      const merchantFound = merchants.find((each) => each.phone === phone);
      merchantFound ? setMerchant(merchantFound) : navigate("/merchants");
    },
    [merchants, navigate, phone],
  )

  useEffect(() => {
    getMerchant();
  }, [getMerchant]);
  return (
    <>
      <PageHeader getMerchant={getMerchant} title="" merchantDetails={merchant} />
      <div className="container-fluid px-4 pt-4">
        <section className="row">
          {/* <div className="col-sm-12 col-md-4 p-2">
            <InfoCard title="User ID" content="AJ/02/001" />
            <InfoCard title="Account Type" content="Merchant" />
          </div>
          <div className="col-sm-12 col-md-4 p-2">
            <InfoCard title="Proof of Address" content="PHCN Bill" />
            <InfoCard title="Contact Address" content="30,Aregbe Street, Fagba Lagos, Nigeria" />
          </div>
          <div className="col-sm-12 col-md-4 p-2">
            <InfoCard title="Proof of ID" content="Drivers License" />
          </div> */}
          {/* <div className="col-sm-12 col-md-4 p-2">
            <InfoCard title="Credit Score" content="8.0" />
            <InfoCard title="Ajobank Score" content="89%" />
            <InfoCard title="Eligibility Amount" content="N100,000.00" />
            <InfoCard title="Interest" content="0.003%" subtitle="Interests will be charged on any new loan and will start after 24hrs of non-payment" />
            <InfoCard title="Repayment Status" content="Ongoing" />
          </div> */}
          {/* <div className="col-sm-12 col-md-4 p-2">
            <InfoCard title="Available Loan Amount" content="N100,000.00" />
            <InfoCard title="Amount Owed" content="N50,000.00" />
            <InfoCard title="Total Repayment Paid" content="N10,000.00" />
            <button className="btn btn-outline-secondary">Review Loan History</button>
          </div> */}
        </section>
      </div>
    </>
  );
}

// function InfoCard({title, content, subtitle}) {
//   return (
//     <div className="fw-bold mb-5">
//       <div className="small text-secondary">{title}</div>
//       <div className="">{content}</div>
//       <div className="extraSmallText fw-light">{subtitle}</div>
//     </div>
//   )
// }
