import React, { useEffect, useState } from "react";
import DataCard from "../../components/dashboard/data-card/DataCard";
import PageHeader from "../../widgets/page-header/PageHeader";
import HelloAdmin from "../../components/dashboard/hello-admin/HelloAdmin";
import TotalTransaction from "../../components/dashboard/total-transaction/TotalTransaction";
import { useSelector } from "react-redux";
import { dashboardSelector } from "../../store/slices/dashboard/dashboardSlice";

const Dashboard = () => {
  const { transactions, total_transactions } = useSelector(dashboardSelector).dashboard;
  const [todayTransactions, setTodayTransactions] = useState([]);
  useEffect(() => {
    let tTransactions = transactions.filter(
      (each) =>
        new Date(each.createdAt).toISOString() === new Date().toISOString()
    );
    setTodayTransactions(tTransactions);
    //eslint-disable-next-line
  }, []);
  return (
    <>
      <PageHeader title="Dashboard" />
      <div className="container-fluid px-4 pt-4">
        <section className="row">
          <section className="row flex-nowrap flex-md-wrap overflow-auto removeScrollbar position-relative">
            <DataCard
              data={{
                title: "Total daily Volume",
                icon: "upDownArrow",
                value: todayTransactions.length,
                backgroundColor: "#061B5A",
                color: "#ffffff",
              }}
            />
          </section>
          <div className="col-sm-12 col-md-6 col-lg-6 p-2">
            <HelloAdmin />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 p-2">
            <TotalTransaction transactions={transactions} total_transactions={total_transactions} />
          </div>
        </section>
      </div>
    </>
  );
};

export default Dashboard;
