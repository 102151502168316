/* eslint-disable no-unused-expressions */
/* eslint-disable linebreak-style */
import React, { useState } from "react";
import PageHeader from "../../widgets/page-header/PageHeader";
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomInput from "../../widgets/form/custom-input/CustomInput";
import CustomLabel from "../../widgets/form/custom-label/CustomLabel";
import CustomButton from "../../widgets/form/custom-button/CustomButton";
import CustomInputTextArea from "../../widgets/form/custom-textarea/CustomTextarea";
import { useSelector } from "react-redux";
import { loginSelector } from "../../store/slices/auth/loginSlice";
import axios from "axios";
import baseUrl, { testUrl } from "../../utils/baseUrl";
import { uploadImage } from "../../utils/functions";

const validationSchema = Yup.object({
  title: Yup.string("Enter notificiation Title")
    .min(2, "Too short!")
    .max(50, "Too long!")
    .required("Title is required"),
  message: Yup.string("Enter your Message")
    .min(2, "Too short!")
    .required("Message is required"),
});
const Notifications = () => {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { userData } = useSelector(loginSelector).login;
  const [isCampaign, setIsCampaign] = useState(false);
  const [isSinglePerson, setIsSinglePerson] = useState(false);
  const [url, setUrl] = useState("");
  const [image, setImage] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");

  const formik = useFormik({
    initialValues: {
      title: "",
      message: "",
      isCampaign: isCampaign || false,
      url: url || "",
      image: image || "",
      recipientType: isSinglePerson ? "single" : "all",
      phoneNumber: phoneNumber || "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
  });

  const handleSubmit = async (values, resetForm) => {
    setLoading(true);
    let images = "";
    const formData = new FormData();
    formData.append("img_pic", image);
    try {
      if (image) {
        const results = await uploadImage(formData);

        if (results.success) {
          const newData = results.data;
          images = newData.url;
        } else {
          setMessage({
            error: true,
            text:
              "An error occur while uploading your Image, please try again.",
          });
          return;
        }
      }

      let dataRequest = {
        ...values,
        adminId: userData.id,
        image: images || "https://getgate.africa/logos/logo.png",
      };

      const response = await axios.post(
        `${testUrl}admin/send-notifications`,
        dataRequest
      );
      const data = response.data;
      setMessage({
        error: data.error,
        text: data.message,
      });
      if (data.error === false || data.status === true) {
        resetForm();
        setLoading(false);
        setTimeout(() => {
          setMessage(null);
        }, 2500);
      }
    } catch (err) {
      console.error("err ", err);
      setLoading(false);
      setMessage({
        error: true,
        text: "An error occur, please try again.",
      });
    }
  };

  const handleChooseImage = async (e) => {
    const file = e.target.files[0]; // Get the selected file
    setImage(file); // Set the image state with the file
    console.log(file); // Log the selected file
  };

  return (
    <>
      <PageHeader title="Send Notification" />

      <div className="container-fluid px-3 mt-3 items-center">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="customFormCard">
              <form onSubmit={formik.handleSubmit} className="gap-3">
                {message && typeof message.text === "string" && (
                  <div
                    className={
                      message.error
                        ? "text-danger text-center"
                        : "text-success text-center"
                    }
                  >
                    {message.text}
                  </div>
                )}
                <div className="mb-3">
                  <CustomLabel htmlFor="title" content="Title" />
                  <CustomInput
                    type="text"
                    placeholder="Enter Title"
                    aria-label="title"
                    aria-describedby="title"
                    name="title"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                  />
                  {formik.touched.title && (
                    <div className="form-text text-danger">
                      {formik.errors.title}
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <CustomLabel htmlFor="isCampaign" content="Is Campaign?" />
                  <select
                    name="isCampaign"
                    className="form-select"
                    onChange={(e) => {
                      formik.handleChange(e);
                      setIsCampaign(e.target.value === "true");
                    }}
                    value={formik.values.isCampaign}
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                </div>

                {isCampaign && (
                  <div className="mb-3">
                    <CustomLabel htmlFor="url" content="Campaign URL" />
                    <CustomInput
                      type="url"
                      placeholder="Enter Campaign URL"
                      aria-label="url"
                      name="url"
                      onChange={formik.handleChange}
                      value={formik.values.url}
                    />
                  </div>
                )}

                <div className="mb-3">
                  <CustomLabel
                    htmlFor="image"
                    content="Upload Image (optional)"
                  />
                  <input
                    type="file"
                    accept="image/*"
                    className="form-control"
                    onChange={handleChooseImage}
                  />
                </div>

                <div className="mb-3">
                  <CustomLabel htmlFor="recipientType" content="Send To" />
                  <select
                    name="recipientType"
                    className="form-select"
                    onChange={(e) => {
                      formik.handleChange(e);
                      setIsSinglePerson(e.target.value === "single");
                    }}
                    value={formik.values.recipientType}
                  >
                    <option value="all">All Users</option>
                    <option value="single">Single Person</option>
                  </select>
                </div>

                {isSinglePerson && (
                  <div className="mb-3">
                    <CustomLabel htmlFor="phoneNumber" content="Phone Number" />
                    <CustomInput
                      type="text"
                      placeholder="Enter Phone Number"
                      aria-label="phoneNumber"
                      name="phoneNumber"
                      onChange={formik.handleChange}
                      value={formik.values.phoneNumber}
                    />
                  </div>
                )}
                <div className="mb-3">
                  <CustomLabel htmlFor="message" content="Message" />
                  <CustomInputTextArea
                    type="text"
                    placeholder="Enter your message"
                    aria-label="message"
                    aria-describedby="message"
                    name="message"
                    onChange={formik.handleChange}
                    value={formik.values.message}
                  />
                  {formik.touched.message && (
                    <div className="form-text text-danger">
                      {formik.errors.message}
                    </div>
                  )}
                </div>

                <div className="d-flex justify-content-between">
                  <CustomButton
                    type="submit"
                    loading={loading}
                    text="Send"
                    color="primary"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;
