import PropTypes from "prop-types";
import { getInitials } from "../../utils/functions";
import styles from "./avatar.module.scss";

export default function Avatar({ src, name, width, height, alt }) {
  if (name) return <div className={styles.initialsAvatar}>{getInitials(name)}</div>;
  return (
    <img
      src={src}
      width={width ? width : "50px"}
      height={height ? height : "50px"}
      className={styles.avatar}
      alt={alt}
    />
  );
}

Avatar.propTypes = {
  src: PropTypes.string,
  isName: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string,
};
