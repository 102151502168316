/* eslint-disable linebreak-style */
import {setData} from "../store/slices/auth/loginSlice";
import {
  getMerchants,
  getTeams,
  getTransactions,
} from "../store/slices/dashboard/dashboardSlice";
import baseUrl from "./baseUrl";

// interceptor and verification goes here
export const interceptRequest = (
  axios,
  {token, setActiveComponent, navigation}
) => {
  axios.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      } else {
        setActiveComponent(navigation);
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
};

export const interceptResponse = (
  axios,
  {setActiveComponent, navigation}
) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      //     setActiveComponent(navigation);
      // const originalRequest = error.config
      if (
        ""
        // error.responseaccountN.status === 401
      ) {
        setActiveComponent(navigation);
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
};

const verify = (
  axios,
  {
    token,
    dispatch,
    setSuccess,
    setActiveComponent,
    navigation,
    networkError,
    user,
  }
) => {
  axios
    .post(`${baseUrl}admin/verify_token`, {token})
    .then((e) => {
      let result = e.data;
      if (result.data) {
        dispatch(setData(result.data));
        //prefetch datas
        dispatch(getTransactions());
        user.role === "super-admin" &&
          dispatch(
            getTeams(false, {
              adminId: user.id,
              super_adminId: user.id,
            })
          );
        dispatch(getMerchants());
        setSuccess(true);
      } else {
        setActiveComponent(navigation);
      }
    })
    .catch((err) => {
      if (err.response?.data?.error) {
        setActiveComponent(navigation);
        setSuccess(true)
      } else {
        setActiveComponent(networkError);
      }
    });
};

export default verify;
