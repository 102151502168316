import {useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import {loginSelector} from "../../../store/slices/auth/loginSlice";
import {navbarItems} from "../../../utils/constants";

export default function NavSidebarContent() {
  const location = useLocation();
  const {userData: {role}} = useSelector(loginSelector).login;
  const teamRoute = {name: "Teams", path: "/teams"};

  function isCurrentRoute(path) {
    const currentPath = location?.pathname;
    return path === currentPath;
  }

  return (
    <ul className="list-group mx-0">
      {
        role === "super-admin" ?
          [...navbarItems, teamRoute].map((item, index) => (
            <li
              className={[
                `list-group-item bg-transparent py-3 ${isCurrentRoute(item.path) ? "active text-white" : "text-white-50"
                }`,
              ].join(" ")}
              aria-current={isCurrentRoute(item.path)}
              key={index}
            >
              <Link
                className="text-decoration-none fw-bold lead d-block"
                style={{color: "inherit"}}
                to={item.path}
              >
                {item.name}
              </Link>
            </li>
          )) : navbarItems.map((item, index) => (
            <li
              className={[
                `list-group-item bg-transparent py-3 ${isCurrentRoute(item.path) ? "active text-white" : "text-white-50"
                }`,
              ].join(" ")}
              aria-current={isCurrentRoute(item.path)}
              key={index}
            >
              <Link
                className="text-decoration-none fw-bold lead d-block"
                style={{color: "inherit"}}
                to={item.path}
              >
                {item.name}
              </Link>
            </li>
          ))}
    </ul>
  );
}
