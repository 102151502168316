import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import baseUrl from "../../../utils/baseUrl";

const initialState = {
  //merchants
  merchants: [],
  merchantIsLoading: false,
  merchantError: null,

  //teams
  teams: [],
  teamsIsLoading: false,
  teamError: null,

  //transactions
  transactions: [],
  total_transactions: {},
  transactionsIsLoading: false,
  transactionsError: null,

  
  // funding request
  fundingRequests: [],
  withdrawalRequests: [],
  gateGroups: [],
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setMerchantIsLoading: (state, { payload }) => {
      state.merchantIsLoading = payload;
    },
    setMerchants: (state, { payload }) => {
      state.merchants = payload;
    },
    setMerchantError: (state, { payload }) => {
      state.merchantError = payload;
    },
    setTeamsIsLoading: (state, { payload }) => {
      state.teamsIsLoading = payload;
    },
    setTeams: (state, { payload }) => {
      state.teams = payload;
      state.teamError = null;
    },
    setTeamError: (state, { payload }) => {
      state.teamError = payload;
    },
    setTransactionsIsLoading: (state, { payload }) => {
      state.transactionsIsLoading = payload;
    },
    setTransactions: (state, { payload }) => {
      state.transactions = payload;
      state.transactionsError = null;
    },
    setTransationsTotal: (state, { payload }) => {
      state.total_transactions = payload;
    },
    setTransactionsError: (state, { payload }) => {
      state.transactionsError = payload;
    },
    
    setFundingRequests: (state, {payload}) => {
      state.fundingRequests = payload;
      state.transactionsError = null;
    },
    
    setWithdrawalRequests: (state, {payload}) => {
      state.withdrawalRequests = payload;
      state.transactionsError = null;
    },

    setGateGroups: (state, {payload}) => {
      state.gateGroups = payload;
      state.transactionsError = null;
    },

    // to get rid of state coming from persist state
    initState: (state, { payload }) => {
      state.merchantError = null;
      state.transactionsError = null;
      state.teamError = null;

      state.teamsIsLoading = false;
      state.transactionsIsLoading = false;
      state.merchantIsLoading = false;
    }
  },
});

//actions
export const {
  setMerchantIsLoading,
  setMerchants,
  setMerchantError,

  setTeamsIsLoading,
  setTeams,
  setTeamError,

  setTransactionsIsLoading,
  setTransactions,
  setTransactionsError,
  setTransationsTotal,

  setFundingRequests,
  setWithdrawalRequests,
  setGateGroups,

  initState
} = dashboardSlice.actions;

//selectors
export const dashboardSelector = (state) => state;

//reducer
export default dashboardSlice.reducer;

//Asynchronous thunk action

//get Merchants
export const getMerchants = (background) => {
  return async (dispatch) => {
    !background && dispatch(setMerchantIsLoading(true));
    !background && dispatch(setMerchantError(null));
    try {
      const result = await axios.get(`${baseUrl}admin/get_all_merchants`);
      const data = result.data;
      if (!data.error || data.status) {
        dispatch(setMerchants(data.data));
      } else {
        dispatch(setMerchantError({ error: true, text: data.message }));
      }
    } catch (error) {
      if (error.response) {
        let err = (error.response);
        dispatch(setMerchantError({ error: true, text: err.data?.message }));
      } else {
        dispatch(setMerchantError({ error: true, text: "An error occured, please check your internet connection" }));
      }
    }
    !background && dispatch(setMerchantIsLoading(false));
  };
};

// getTeams
export const getTeams = (background, details) => {
  return async (dispatch) => {
    !background && dispatch(setTeamError(null));
    !background && dispatch(setTeamsIsLoading(true));
    try {
      const result = await axios.post(`${baseUrl}admin/get_all_admin`, details);
      const data = result.data;
      if (!data.error || data.status) {
        if (data.data[0]) {
          dispatch(setTeams(data.data));
        } else {
          dispatch(setTeams([]));
        }
      } else {
        dispatch(setTeamError({ error: true, text: data.message }));
      }
    } catch (error) {
      if (error.response) {
        let err = (error.response);
        dispatch(setTeamError({ error: true, text: err.data?.message }));
      } else {
        dispatch(setTeamError({ error: true, text: "An error occured, please check your internet connection" }));
      }
    }
    !background && dispatch(setTeamsIsLoading(false));
  };
};

// delete an admin
export const deleteMember = (details, actions) => {
  return async (dispatch) => {
    try {
      const result = await axios.post(`${baseUrl}admin/delete_admin`, details);
      const data = result.data;
      if (!data.error || data.status) {
        actions.setMessage({ text: "Member deleted successfully successfully" });
        actions.setOpen(false);
        actions.setTobeDeleted(null);
      } else {
        actions.setMessage({ error: true, text: data.message });
      }
    } catch (error) {
      if (error.response) {
        let err = (error.response);
        actions.setMessage({ error: true, text: err.data?.message });
      } else {
        actions.setMessage({ error: true, text: "An error occured, please check your internet connection" });
      }
    }
    await dispatch(getTeams(true, {
      "adminId": details.super_adminId,
      "super_adminId": details.super_adminId
    }))
  };
};

// verify merchant
export const verifyMerchant = async (details) => {
  const result = await axios.post(`${baseUrl}admin/verify_merchant`, details);
  const data = result.data;
  return data
};

// add new member to teams
export const newMember = (details, actions) => {
  return async (dispatch) => {
    try {
      const result = await axios.post(`${baseUrl}admin/create_admin_team`, details);
      const data = result.data;
      if (!data.error || data.status) {
        actions.resetForm();
        actions.setMessage({ text: "Member added successfully" });
      } else {
        actions.setMessage({ error: true, text: data.message });
      }
    } catch (error) {
      if (error.response) {
        let err = (error.response);
        actions.setMessage({ error: true, text: err.data?.message });
      } else {
        actions.setMessage({ error: true, text: "An error occured, please check your internet connection" });
      }
    }
  };
};

// getTransactions
export const getTransactions = (background) => {
  return async (dispatch) => {
    !background && dispatch(setTransactionsError(null));
    !background && dispatch(setTransactionsIsLoading(true));
    try {
      const result = await axios.get(`${baseUrl}admin/get_all_transactions`);
      const data = result.data;
      if (!data.error || data.status) {
        dispatch(setTransactions(data.data));
        dispatch(setTransationsTotal({ total_transaction: data.total_transaction, break_down: data.all_tfs }));
      } else {
        dispatch(setTransactionsError({ error: true, text: data.message }));
      }
    } catch (error) {
      if (error.response) {
        let err = (error.response);
        dispatch(setTransactionsError({ error: true, text: err.data?.message }));
      } else {
        dispatch(setTransactionsError({ error: true, text: "An error occured, please check your internet connection" }));
      }
    }
    !background && dispatch(setTransactionsIsLoading(false));
  };
};

// Getting Fund requests
export const getFundRequest = (background) => {
  return async (dispatch) => {
    !background && dispatch(setTransactionsError(null));
    !background && dispatch(setTransactionsIsLoading(true));
    try {
      const result = await axios.get(`${baseUrl}admin/get_all_fund_request`);
      const data = result.data;
      if (!data.error || data.status) {
        console.log(data.data);
        dispatch(setFundingRequests(data.data));
      } else {
        dispatch(setTransactionsError({error: true, text: data.message}));
      }
    } catch (error) {
      if (error.response) {
        let err = (error.response);
        dispatch(setTransactionsError({error: true, text: err.data?.message}));
      } else {
        dispatch(setTransactionsError({error: true, text: "An error occured, please check your internet connection"}));
      }
    }
    !background && dispatch(setTransactionsIsLoading(false));
  };
};


// Getting withdrawal requests
export const getwithdrawalRequest = (background) => {
  return async (dispatch) => {
    !background && dispatch(setTransactionsError(null));
    !background && dispatch(setTransactionsIsLoading(true));
    try {
      const result = await axios.get(`${baseUrl}admin/get_all_withdrawal_request`);
      const data = result.data;
      if (!data.error || data.status) {
        console.log(data.data);
        dispatch(setWithdrawalRequests(data.data));
      } else {
        dispatch(setTransactionsError({error: true, text: data.message}));
      }
    } catch (error) {
      if (error.response) {
        let err = (error.response);
        dispatch(setTransactionsError({error: true, text: err.data?.message}));
      } else {
        dispatch(setTransactionsError({error: true, text: "An error occured, please check your internet connection"}));
      }
    }
    !background && dispatch(setTransactionsIsLoading(false));
  };
};

// Getting withdrawal requests
export const getGateGroups = (background) => {
  return async (dispatch) => {
    !background && dispatch(setTransactionsError(null));
    !background && dispatch(setTransactionsIsLoading(true));
    try {
      const result = await axios.get(`${baseUrl}admin/get_all_groups`);
      const data = result.data;
      if (!data.error || data.status) {
        console.log(data.data);
        dispatch(setGateGroups(data.data));
      } else {
        dispatch(setTransactionsError({error: true, text: data.message}));
      }
    } catch (error) {
      if (error.response) {
        let err = (error.response);
        dispatch(setTransactionsError({error: true, text: err.data?.message}));
      } else {
        dispatch(setTransactionsError({error: true, text: "An error occured, please check your internet connection"}));
      }
    }
    !background && dispatch(setTransactionsIsLoading(false));
  };
};