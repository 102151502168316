import {combineReducers} from "redux";
import loginReducer from "./slices/auth/loginSlice";
import dashboardSlice from "./slices/dashboard/dashboardSlice";

const rootReducer = combineReducers({
  login: loginReducer,
  dashboard: dashboardSlice
});

export default rootReducer;
